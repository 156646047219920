import { onboardingDataStore } from '@/services/OnboardingData/store'

export const methods = {
  async reload() {
    this.loading = true

    await this.loadFinancialInformation()

    this.loading = false
  },

  async loadFinancialInformation() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      await onboardingDataStore
        .dispatch('getOnboardingData', {
          hoaID: this.hoaId
        })
        .then(({ result }) => {
          if (result) {
            if (this.isDebug === true)
              console.debug('loadFinancialInformation=' + JSON.stringify(result))

            this.financialInformation = result

            this.formData.federalTaxId = result.federalTaxID || ''
            this.formData.assessments = result.assessments || ''
            this.formData.lateFees = result.lateFees || ''
            this.formData.closingFees = result.closingFees || ''
            this.formData.parcelTaxNumbers = result.parcelTaxNumbers || ''
          }
        })
    } catch (exception) {
      loadingComponent.close()
    }

    loadingComponent.close()
  },

  async onFormSubmit() {
    if (this.isDebug == true) console.debug('in onFormSubmit....' + JSON.stringify(this.formData))

    if (
      this.financialInformation &&
      this.financialInformation.onboardingDataID &&
      this.financialInformation.onboardingDataID != undefined
    ) {
      const payload = {
        onboardingDataID: this.financialInformation.onboardingDataID,
        hoaID: this.hoaId,
        federalTaxID: this.formData.federalTaxId,
        assessments: this.formData.assessments,
        lateFees: this.formData.lateFees,
        closingFees: this.formData.closingFees,
        parcelTaxNumbers: this.formData.parcelTaxNumbers,
        updateFederalTaxOnly: true
      }

      await onboardingDataStore.dispatch('updateOnboardingFederalIdData', {
        payload: payload,
        done: () => {}
      })
    } else {
      await onboardingDataStore.dispatch('addOnboardingFederalIdData', {
        hoaID: this.hoaId,
        federalTaxID: this.formData.federalTaxId,
        done: () => {}
      })
    }
  },
  returnToParent() {
    this.$router.push({
      name: 'home'
    })
  }
}
