<template>
  <div class="section about has-text-centered" tests-id="management-dashboard">
    <div class="columns column is-12">
      <div class="column is-6" :style="styles.tilesContainer">
        <OnBoardingCard :data="financialinfo" />
      </div>
      <div class="column is-6" :style="styles.tilesContainer">
        <OnBoardingCard
          :selectedName.sync="selectedName"
          :selectedID.sync="selectedID"
          :selectedIndex.sync="selectedIndex"
          :clearFilesAndPreviews.sync="clearFilesAndPreviews"
          :data="financialdocuments"
        />
      </div>
    </div>
    <div class="columns column is-12">
      <div class="column is-6" :style="styles.tilesContainer">
        <OnBoardingCard :data="boardAndCommittees" />
      </div>
      <div class="column is-6" :style="styles.tilesContainer">
        <OnBoardingCard
          :selectedName.sync="selectedName"
          :selectedID.sync="selectedID"
          :selectedIndex.sync="selectedIndex"
          :clearFilesAndPreviews.sync="clearFilesAndPreviews"
          :data="governingdocuments"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-6" :style="styles.tilesContainer">
        <OnBoardingCard
          :selectedName.sync="selectedName"
          :selectedID.sync="selectedID"
          :selectedIndex.sync="selectedIndex"
          :clearFilesAndPreviews.sync="clearFilesAndPreviews"
          :data="ancillarydocuments"
        />
      </div>
      <div class="column is-6" :style="styles.tilesContainer">
        <OnBoardingCard
          :selectedName.sync="selectedName"
          :selectedID.sync="selectedID"
          :selectedIndex.sync="selectedIndex"
          :clearFilesAndPreviews.sync="clearFilesAndPreviews"
          :data="homeownersroster"
        />
      </div>
      <div class="column is-6 p-6">&nbsp;</div>
    </div>
    <div class="columns">
      <div class="column is-6" :style="styles.tilesContainer">
        <OnBoardingCard
          :selectedName.sync="selectedName"
          :selectedID.sync="selectedID"
          :data="merchantonboarding"
        />
      </div>

      <div class="column is-6 p-6">&nbsp;</div>
    </div>
    <Modal :toggle.sync="toggle">
      <BoardingModal :onboardingData="onboardingData" />
    </Modal>
  </div>
</template>

<script type="es6">
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'

// Components
import OnBoardingCard from '@/components/cards/OnBoardingCard'
import BoardingModal from './MerchantBoarding/Boarding/components/BoardingModal'
import Modal from '@/components/Modal'

export default {
  name: 'OnBoardingDashboard',

  data,

  methods,

  components: {
    OnBoardingCard,
    BoardingModal,
    Modal
  },

  watch: {
    files() {
      this.updateAttachments()
    },
    toggle() {
      if (this.toggle == false) {
        this.reload()
        this.toggle = false
        if (this.Modal && this.Modal != undefined) {
          this.Modal.$emit('update:toggle', (this.toggle = false))
        }
      }
    }
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('user', ['authUser'])
  },

  async mounted() {
    await this.reload()
  }
}
</script>

<style></style>
