<template>
  <PageContentLayoutOnly role="region" board-and-committees>
    <section class="section">
      <div class="pl-5 pr-5">
        <div class="level pl-5 pr-5">
          <div class="pt-5 level-left level-item title">{{ $t('boardAndCommittees.title') }}</div>
          <div class="level-right">
            <div class="level-item">
              <svg
                width="30"
                height="30"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 19l-7-7 7-7"
                ></path>
              </svg>
            </div>
            <div class="level-item">
              <b-button label="Back to Onboarding" @click.prevent="returnToParent()" />
            </div>
          </div>
        </div>
        <div class="box is-12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <transition name="fade" mode="out-in" appear>
              <form @submit.prevent="handleSubmit(onFormSubmit)" class="form">
                <fieldset>
                  <div>
                    <div class="column is-12">
                      <valid-input
                        style="width:50px !important;"
                        name="boardMembers"
                        label="Board Members"
                        maxlength="8000"
                        type="textarea"
                        vid="BoardMembers"
                        placeholder="Board Members"
                        spellcheck="true"
                        aria-label="Board Members"
                        rules="max:8000|required"
                        class="is-small"
                        v-model="boardMembers"
                      />
                    </div>
                    <div class="column is-12">
                      <valid-input
                        style="width:50px !important;"
                        name="committeeMembers"
                        label="Committee Members"
                        maxlength="8000"
                        type="textarea"
                        vid="CommitteeMembers"
                        placeholder="Committee Members"
                        spellcheck="true"
                        aria-label="Committee Members"
                        rules="max:8000|required"
                        class="is-small"
                        v-model="committeeMembers"
                      />
                    </div>
                    <div class="column is-12">
                      <button type="submit" class="button is-primary is-rounded">
                        Submit
                      </button>
                    </div>
                  </div>
                </fieldset>
                <fieldset>
                  <ValidationProvider vid="providerErrors" v-slot="{ errors }">
                    <div class="field">
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </fieldset>
              </form>
            </transition>
          </ValidationObserver>
        </div>
      </div>
    </section>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ValidInput from '@/components/inputs/ValidInput'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider,
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('user', ['authUser'])
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { boardAndCommittees: { title: 'Board and Committees' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>
