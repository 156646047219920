/*
  import { onboardingDataStore } from '@/services/OnboardingData/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import { notifyProblem, notifyError, notifyMessage } from '@/services/notify'
import kms from '@/services/kms'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async getOnboardingData({}, { hoaID }) {
    try {
      if (isDebug === true) console.debug('in getOnboardingData...' + hoaID)

      const onboardingData = await kms.get(`/Onboarding/OnboardingData/GetByHoaId?hoaID=${hoaID}`)

      if (isDebug == true) console.debug('getOnboardingData=' + JSON.stringify(onboardingData))

      return {
        result: onboardingData
      }
    } catch (e) {
      console.debug(e.message)
      console.debug(`There was a problem retrieving the onboarding data.`)
      //console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getMerchantBoardingData({}, { hoaID }) {
    try {
      if (isDebug === true) console.debug('in getMerchantBoardingData...' + hoaID)

      const merchantBoardingData = await kms.get(
        `/Utility/MerchantBoardingUtility/RetrieveMerchantBoardingValues`
      )

      if (isDebug == true)
        console.debug('getMerchantBoardingData=' + JSON.stringify(merchantBoardingData))

      return {
        result: merchantBoardingData
      }
    } catch (e) {
      console.debug(e.message)
      console.debug(`There was a problem retrieving the merchant boarding data.`)
    }

    return {
      result: {}
    }
  },

  // Update
  async updateOnboardingFederalIdData({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Onboarding/OnboardingData?updateFederalTaxOnly=true`, payload)

      if (this.isDebug == true)
        console.debug('updateOnboardingFederalIdData=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.onboardingDataID > 0) {
        notifyMessage(`Successfully updated your financial information.`)
        done()
      } else {
        notifyError('There was a problem updating your financial information.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem processing the update for your financial information.`)
      console.error(exception)
    }
  },

  // Update
  async updateOnboardingBoardAndCommitteesData({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(
        `/Onboarding/OnboardingData?updateBoardAndCommitteesOnly=true`,
        payload
      )

      if (this.isDebug == true)
        console.debug('updateOnboardingBoardAndCommitteesData=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.onboardingDataID > 0) {
        notifyMessage(`Successfully updated your board and committees information.`)
        done()
      } else {
        notifyError('There was a problem updating your board and committees information.')
      }
    } catch (exception) {
      notifyProblem(
        `There was a problem processing the update for your board and committees information.`
      )
      console.error(exception)
    }
  },

  // Add
  async addOnboardingFederalIdData({ dispatch }, { hoaID, federalTaxID, done }) {
    if (isDebug == true) console.debug('...in addOnboardingFederalIdData' + this.federalTaxID)
    try {
      const results = await kms.post(`/Onboarding/OnboardingData`, {
        hoaID,
        federalTaxID
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.onboardingDataID > 0) {
        notifyMessage(`Successfully added your financial information.`)
        done()
      } else {
        notifyError('There was a problem adding your financial information.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding your financial information.`)
      console.error(exception)
    }
  },

  // Update Boarding Merchant
  async updateOnboardingMerchantData({ dispatch }, { payload = {}, done }) {
    if (isDebug == true) console.debug('...in updateOnboardingMerchantData')
    try {
      const results = await kms.put(`/Onboarding/OnboardingData`, payload)

      if (this.isDebug == true)
        console.debug('updateOnboardingFederalIdData=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.onboardingDataID > 0) {
        notifyMessage(`Successfully updated your merchant boarding information.`)
        done({ results })
      } else {
        notifyError('There was a problem updating your merchant boarding information.')
      }
    } catch (exception) {
      notifyProblem(
        `There was a problem processing the update for your merchant boarding information.`
      )
      console.error(exception)
    }
  },

  async fortisBoardMerchant({ dispatch }, { payload, done }) {
    if (isDebug == true) console.debug('...in fortisBoardMerchant=' + JSON.stringify(payload))
    try {
      /*
{
  "template_id": 234234,
  "dba_name": "dba name",
  "app_delivery": "link_iframe",
  "email": "test@test.com",
  "website": null,
  "ownership_type": null,
  "fed_tax_id": "3453454353",
  "cc_average_ticket_range": 0,
  "cc_monthly_volume_range": 0,
  "cc_high_ticket": 0,
  "ec_average_ticket_range": 0,
  "ec_monthly_volume_range": 0,
  "ec_high_ticket": 0,
  "business_type": "na",
  "business_category": "na",
  "business_description": "na",
  "swiped_percent": 0,
  "keyed_percent": 0,
  "ecommerce_percent": 0,
  "legal_name": null,
  "location": {
    "address_line_1": null,
    "address_line_2": null,
    "city": null,
    "state_province": null,
    "postal_code": null,
    "phone_number": "123 123-1234"
  },
  "primary_principal": {
    "first_name": "First",
    "last_name": "Last",
    "middle_name": null,
    "title": null,
    "date_of_birth": null,
    "address_line_1": null,
    "address_line_2": null,
    "city": null,
    "state_province": null,
    "postal_code": null,
    "ssn": null,
    "ownership_percent": 0,
    "phone_number": "123 123-1234"
  },
  "contact": {
    "first_name": null,
    "last_name": null,
    "email": null,
    "phone_number": "123 123-1234"
  },
  "bank_account": {
    "routing_number": "0",
    "account_number": "0",
    "account_holder_name": "First Last"
  },
  "is_test": "true",
  "app_complete_endpoint": null
}
      */
      const results = await kms.post(`/FortisEpicGateway/EpicGateway/BoardMerchant`, {
        template_id: payload.template_id,
        dba_name: payload.dba_name,
        email: payload.email,
        app_delivery: payload.app_delivery,
        fed_tax_id: payload.fed_tax_id,
        cc_average_ticket_range: payload.cc_average_ticket_range,
        cc_monthly_volume_range: payload.cc_monthly_volume_range,
        cc_high_ticket: payload.cc_high_ticket,
        ec_average_ticket_range: payload.ec_average_ticket_range,
        ec_monthly_volume_range: payload.ec_monthly_volume_range,
        ec_high_ticket: payload.ec_high_ticket,
        business_type: payload.business_type,
        business_category: payload.business_category,
        business_description: payload.business_description,
        swiped_percent: payload.swiped_percent,
        keyed_percent: payload.keyed_percent,
        ecommerce_percent: payload.ecommerce_percent,
        location: {
          phone_number: payload.location.phone_number
        },
        primary_principal: {
          ownership_percent: payload.primary_principal.ownership_percent,
          first_name: payload.primary_principal.first_name,
          last_name: payload.primary_principal.last_name
        },
        contact: {
          phone_number: payload.contact.phone_number
        },
        bank_account: {
          routing_number: payload.bank_account.routing_number,
          account_number: payload.bank_account.account_number,
          account_holder_name: payload.bank_account.account_holder_name
        },
        is_test: payload.is_test,
        app_complete_endpoint: payload.app_complete_endpoint
      })

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results && results !== undefined) {
        notifyMessage(`Successfully retrieved your merchant app link.`)
        done()
      } else {
        notifyError('There was a problem adding your merchant app link.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem adding your merchant app link.`)
      console.error(exception)
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const onboardingDataStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
