import moment from 'moment'

export const data = function() {
  return {
    isDebug: true,
    loading: false,
    toggle: false,
    boardMembers: '',
    committeeMembers: '',
    boardAndCommittees: null,
    styles: {
      mainContainer: {
        minHeight: '1000px'
      }
    },
    formatDateFn: function(value) {
      return value != null
        ? moment(value, 'YYYY/MM/DD HH:mm:ss').format('MM-DD-YYYY hh:mm:ss a')
        : ''
    }
  }
}
