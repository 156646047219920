import { onboardingDataStore } from '@/services/OnboardingData/store'
import { notifyError } from '@/services/notify'

export const methods = {
  async reload() {
    this.loading = true

    await this.loadBoardAndCommittees()

    this.loading = false
  },

  async loadBoardAndCommittees() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      await onboardingDataStore
        .dispatch('getOnboardingData', {
          hoaID: this.hoaId
        })
        .then(({ result }) => {
          if (result) {
            if (this.isDebug === true)
              console.debug('loadBoardAndCommittees=' + JSON.stringify(result))

            this.boardAndCommittees = result

            this.boardMembers = result.boardMembers || ''
            this.committeeMembers = result.committeeMembers || ''
          }
        })
    } catch (exception) {
      loadingComponent.close()
    }

    loadingComponent.close()
  },

  async onFormSubmit() {
    if (this.isDebug == true) console.debug('in onFormSubmit....' + this.federalTaxId)

    if (
      this.boardAndCommittees &&
      this.boardAndCommittees.onboardingDataID &&
      this.boardAndCommittees.onboardingDataID != undefined
    ) {
      if (!this.boardAndCommittees.federalTaxID) {
        notifyError(
          'Please enter your Federal Tax Id, in the Financial information section, prior to entering board and committee members.'
        )
        return
      }

      const payload = {
        onboardingDataID: this.boardAndCommittees.onboardingDataID,
        federalTaxID: this.boardAndCommittees.federalTaxID,
        hoaID: this.hoaId,
        boardMembers: this.boardMembers,
        committeeMembers: this.committeeMembers,
        updateBoardAndCommitteesOnly: true
      }

      await onboardingDataStore.dispatch('updateOnboardingBoardAndCommitteesData', {
        payload: payload,
        done: () => {}
      })
    }
  },

  returnToParent() {
    this.$router.push({
      name: 'home'
    })
  }
}
