import { onboardingTaskStore } from '@/services/OnboardingTask/store'
import { onboardingDataStore } from '@/services/OnboardingData/store'
import { base64toBlob, showPdf } from '@/services/blobutility'
import { notifyMessage, notifyProblem, notifyWarning, notifyError } from '@/services/notify'
import kms from '@/services/kms'
import _get from 'lodash/get'

export const methods = {
  async openBoardingModal() {
    if (this.isDebug === true) console.debug('in openBoardingModal...')

    if (
      this.onboardingData &&
      this.onboardingData.federalTaxID &&
      this.onboardingData.federalTaxID != undefined
    ) {
      this.toggle = true
    }
  },

  async reload() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    await Promise.all([
      await this.loadFinancialStatus(),
      await this.loadFinancialDocumentStatus(),
      await this.loadBoardAndCommitteesStatus(),
      await this.loadRosterStatus(),
      await this.loadGoverningStatus(),
      await this.loadAncillaryStatus(),
      await this.loadMerchantStatus(),
      await this.loadDocumentFinancialList(),
      await this.loadDocumentRosterList(),
      await this.loadDocumentGoverningList(),
      await this.loadDocumentAncillaryList(),
      await this.getMerchantDefaults(),
      await this.loadOnboardingData()
    ])

    loadingComponent.close()
  },

  async loadOnboardingData() {
    try {
      await onboardingDataStore
        .dispatch('getOnboardingData', {
          hoaID: this.hoaId,
          codeName: 'Financial'
        })
        .then(({ result }) => {
          if (result) {
            this.onboardingData = result
            ;(this.merchantonboarding.merchantData.hasFederalTaxID =
              this.onboardingData &&
              this.onboardingData.federalTaxID &&
              this.onboardingData.federalTaxID !== undefined
                ? true
                : false),
              (this.merchantonboarding.merchantData.merchantAppLink =
                this.onboardingData &&
                this.onboardingData.merchantAppLink &&
                this.onboardingData.merchantAppLink !== undefined
                  ? this.onboardingData.merchantAppLinkAndClientAppId
                  : this.defaultIframeUrl)
            this.merchantonboarding.merchantData.linkText =
              this.onboardingData &&
              this.onboardingData.merchantAppLink &&
              this.onboardingData.merchantAppLink !== undefined
                ? 'Merchant App Link'
                : 'Please enter Merchant Boarding'
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadOnboardingData()')
    }
  },

  async getMerchantDefaults() {
    try {
      await onboardingDataStore
        .dispatch('getMerchantBoardingData', {
          hoaID: this.hoaId
        })
        .then(({ result }) => {
          if (result) {
            this.defaultIframeUrl = _get(result, 'merchantBoardingIframeUrl', '')
          }
        })
    } catch (exception) {
      console.debug(exception.message)
    }
  },

  async loadFinancialStatus() {
    try {
      await onboardingTaskStore
        .dispatch('getOnboardingTaskByCodeName', {
          hoaID: this.hoaId,
          codeName: 'Financial'
        })
        .then(({ result }) => {
          if (result) {
            this.onboardingData = result
            this.financialinfo.summaryData.status =
              result.isCompleted && result.isCompleted === true ? 'Complete' : 'Incomplete'

            if (this.isDebug === true) console.debug('loadFinancialStatus=' + result.isCompleted)
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadFinancialStatus()')
    }
  },

  async loadBoardAndCommitteesStatus() {
    try {
      await onboardingTaskStore
        .dispatch('getOnboardingTaskByCodeName', {
          hoaID: this.hoaId,
          codeName: 'BoardAndCommittees'
        })
        .then(({ result }) => {
          if (result) {
            this.onboardingData = result
            this.boardAndCommittees.summaryData.status =
              result.isCompleted && result.isCompleted === true ? 'Complete' : 'Incomplete'

            if (this.isDebug === true)
              console.debug('loadBoardAndCommitteesStatus=' + result.isCompleted)
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadBoardAndCommitteesStatus()')
    }
  },

  async loadRosterStatus() {
    try {
      await onboardingTaskStore
        .dispatch('getOnboardingTaskByCodeName', {
          hoaID: this.hoaId,
          codeName: 'Roster'
        })
        .then(({ result }) => {
          if (result) {
            this.homeownersroster.summaryData.status =
              result.isCompleted && result.isCompleted === true ? 'Complete' : 'Incomplete'

            if (this.isDebug === true) console.debug('loadRosterStatus=' + result.isCompleted)
          }
        })

      await onboardingTaskStore
        .dispatch('getDocumentTypeList', {
          category: 'Roster',
          requiredOnly: false,
          hoaID: this.hoaId
        })
        .then(({ list }) => {
          if (list) {
            let rosterRequiredList = (this.homeownersroster.typeList = list
              .map(m => ({
                col1: m.name || '',
                col2: m.isRequiredFileUploaded === true ? 'Yes' : 'No',
                col3: m.isRequired || false
              }))
              .filter(f => f.col3 === true))

            this.homeownersroster.typeList = list
              .map(m => ({
                value: m.onboardingDocumentTypeID,
                label: m.name,
                selected: false
              }))
              .filter(f => f.label !== '' && f.value !== 0)
            this.homeownersroster.typeList.unshift({ value: 0, label: 'Type:', selected: true })

            if (this.isDebug === true)
              console.debug('typeList=' + JSON.stringify(this.homeownersroster.typeList))

            this.homeownersroster.requiredList = rosterRequiredList
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadRosterStatus()')
    }
  },

  async loadGoverningStatus() {
    try {
      await onboardingTaskStore
        .dispatch('getOnboardingTaskByCodeName', {
          hoaID: this.hoaId,
          codeName: 'Governing'
        })
        .then(({ result }) => {
          if (result) {
            this.governingdocuments.summaryData.status =
              result.isCompleted && result.isCompleted === true ? 'Complete' : 'Incomplete'

            if (this.isDebug === true) console.debug('loadGoverningStatus=' + result.isCompleted)
          }
        })

      await onboardingTaskStore
        .dispatch('getDocumentTypeList', {
          category: 'Governing',
          requiredOnly: false,
          hoaID: this.hoaId
        })
        .then(({ list }) => {
          if (list) {
            let goveringRequiredList = (this.governingdocuments.typeList = list
              .map(m => ({
                col1: m.name || '',
                col2: m.isRequiredFileUploaded === true ? 'Yes' : 'No',
                col3: m.isRequired || false
              }))
              .filter(f => f.col3 === true))

            this.governingdocuments.typeList = list
              .map(m => ({
                value: m.onboardingDocumentTypeID,
                label: m.name,
                selected: false
              }))
              .filter(f => f.label !== '' && f.value !== 0)

            this.governingdocuments.typeList.unshift({ value: 0, label: 'Type:', selected: true })

            if (this.isDebug === true)
              console.debug(
                'governingdocuments typeList=' + JSON.stringify(this.governingdocuments.typeList)
              )

            this.governingdocuments.requiredList = goveringRequiredList
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadGoverningStatus()')
    }
  },

  async loadFinancialDocumentStatus() {
    try {
      await onboardingTaskStore
        .dispatch('getOnboardingTaskByCodeName', {
          hoaID: this.hoaId,
          codeName: 'FinancialDocuments'
        })
        .then(({ result }) => {
          if (result) {
            this.financialdocuments.summaryData.status =
              result.isCompleted && result.isCompleted === true ? 'Complete' : 'Incomplete'

            if (this.isDebug === true) console.debug('loadFinancialStatus=' + result.isCompleted)
          }
        })

      await onboardingTaskStore
        .dispatch('getDocumentTypeList', {
          category: 'Financial',
          requiredOnly: false,
          hoaID: this.hoaId
        })
        .then(({ list }) => {
          if (list) {
            let financialRequiredList = (this.financialdocuments.typeList = list
              .map(m => ({
                col1: m.name || '',
                col2: m.isRequiredFileUploaded === true ? 'Yes' : 'No',
                col3: m.isRequired || false
              }))
              .filter(f => f.col3 === true))

            this.financialdocuments.typeList = list
              .map(m => ({
                value: m.onboardingDocumentTypeID,
                label: m.name,
                selected: false
              }))
              .filter(f => f.label !== '' && f.value !== 0)

            this.financialdocuments.typeList.unshift({ value: 0, label: 'Type:', selected: true })

            if (this.isDebug === true)
              console.debug(
                'financial typeList=' + JSON.stringify(this.financialdocuments.typeList)
              )

            this.financialdocuments.requiredList = financialRequiredList
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadFinancialDocumentStatus()')
    }
  },

  async loadAncillaryStatus() {
    try {
      await onboardingTaskStore
        .dispatch('getOnboardingTaskByCodeName', {
          hoaID: this.hoaId,
          codeName: 'Ancillary'
        })
        .then(({ result }) => {
          if (result) {
            this.ancillarydocuments.summaryData.status =
              result.isCompleted && result.isCompleted === true ? 'Complete' : 'Incomplete'

            if (this.isDebug === true) console.debug('loadAncillaryStatus=' + result.isCompleted)
          }
        })

      await onboardingTaskStore
        .dispatch('getDocumentTypeList', {
          category: 'Ancillary',
          requiredOnly: false,
          hoaID: this.hoaId
        })
        .then(({ list }) => {
          if (list) {
            let ancillaryRequiredList = (this.ancillarydocuments.typeList = list
              .map(m => ({
                col1: m.name || '',
                col2: m.isRequiredFileUploaded === true ? 'Yes' : 'No',
                col3: m.isRequired || false
              }))
              .filter(f => f.col3 === true))

            this.ancillarydocuments.typeList = list
              .map(m => ({
                value: m.onboardingDocumentTypeID,
                label: m.name,
                selected: false
              }))
              .filter(f => f.label !== '' && f.value !== 0)

            this.ancillarydocuments.typeList.unshift({ value: 0, label: 'Type:', selected: true })

            if (this.isDebug === true)
              console.debug('typeList=' + JSON.stringify(this.homeownersroster.typeList))

            this.ancillarydocuments.requiredList = ancillaryRequiredList
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadAncillaryStatus()')
    }
  },

  async loadMerchantStatus() {
    try {
      await onboardingTaskStore
        .dispatch('getOnboardingTaskByCodeName', {
          hoaID: this.hoaId,
          codeName: 'Merchant'
        })
        .then(({ result }) => {
          if (result) {
            this.merchantonboarding.summaryData.status =
              result.isCompleted && result.isCompleted === true ? 'Complete' : 'Incomplete'

            if (this.isDebug === true) console.debug('loadMerchantStatus=' + result.isCompleted)
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadMerchantStatus()')
    }
  },

  async loadDocumentRosterList() {
    try {
      this.homeownersroster.list = []
      let rosterList = []

      await onboardingTaskStore
        .dispatch('getDocumentList', {
          hoaID: this.hoaId,
          category: 'Roster'
        })
        .then(({ list }) => {
          if (list) {
            if (this.isDebug === true)
              console.debug('loadDocumentRosterList=' + JSON.stringify(list))

            Array.from(list).forEach(e => {
              if (e) {
                console.log('e=' + JSON.stringify(e))

                rosterList.push({
                  col1: e.onboardingDocumentType.name,
                  col2: e.document.filename,
                  col3: e.onboardingDocumentID
                })
              }
            })

            this.homeownersroster.list = rosterList
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadDocumentRosterList()')
    }
  },

  async loadDocumentGoverningList() {
    try {
      this.governingdocuments.list = []
      let governingList = []

      await onboardingTaskStore
        .dispatch('getDocumentList', {
          hoaID: this.hoaId,
          category: 'Governing'
        })
        .then(({ list }) => {
          if (list) {
            if (this.isDebug === true)
              console.debug('loadDocumentGoverningList=' + JSON.stringify(list))

            Array.from(list).forEach(e => {
              if (e) {
                console.log('e=' + JSON.stringify(e))

                governingList.push({
                  col1: e.onboardingDocumentType.name,
                  col2: e.document.filename,
                  col3: e.onboardingDocumentID
                })
              }
            })

            this.governingdocuments.list = governingList
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadDocumentRosterList()')
    }
  },

  async loadDocumentAncillaryList() {
    try {
      this.ancillarydocuments.list = []
      let ancillaryList = []

      await onboardingTaskStore
        .dispatch('getDocumentList', {
          hoaID: this.hoaId,
          category: 'Ancillary'
        })
        .then(({ list }) => {
          if (list) {
            if (this.isDebug === true)
              console.debug('loadDocumentAncillaryList=' + JSON.stringify(list))

            Array.from(list).forEach(e => {
              if (e) {
                console.log('e=' + JSON.stringify(e))

                ancillaryList.push({
                  col1: e.onboardingDocumentType.name,
                  col2: e.document.filename,
                  col3: e.onboardingDocumentID
                })
              }
            })

            this.ancillarydocuments.list = ancillaryList
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadDocumentAncillaryList()')
    }
  },

  async loadDocumentFinancialList() {
    try {
      this.financialdocuments.list = []
      let financialList = []

      await onboardingTaskStore
        .dispatch('getDocumentList', {
          hoaID: this.hoaId,
          category: 'Financial'
        })
        .then(({ list }) => {
          if (list) {
            if (this.isDebug === true)
              console.debug('loadDocumentFinancialList=' + JSON.stringify(list))

            Array.from(list).forEach(e => {
              if (e) {
                console.log('e=' + JSON.stringify(e))

                financialList.push({
                  col1: e.onboardingDocumentType.name,
                  col2: e.document.filename,
                  col3: e.onboardingDocumentID
                })
              }
            })

            this.financialdocuments.list = financialList
          }
        })
    } catch (exception) {
      console.debug('There was an issue loading loadDocumentFinancialList()')
    }
  },

  async downloadPdf(id) {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (id && id > 0) {
      if (this.isDebug == true) console.debug('downloadPdf...')

      if (!id || id == undefined) {
        notifyProblem('There was a problem downloading this document.')
      }

      try {
        const params = {
          onboardingDocumentID: id,
          asBase64: true,
          asRawBase64: true
        }

        const path = `/Onboarding/OnboardingDocument/GetFile`
        await kms
          .get(path, {
            params
          })
          .then(returned => {
            if (this.isDebug == true)
              console.debug(
                'path base64 path=' +
                  path +
                  ' - params' +
                  JSON.stringify(params) +
                  ' - returned=' +
                  JSON.stringify(returned)
              )

            if (returned && returned != undefined) {
              if (this.isDebug == true)
                console.debug('base64pdf........................' + returned)
              const contentType = 'application/pdf'
              const blob = base64toBlob(returned, contentType)
              showPdf(blob)
              loadingComponent.close()
              notifyMessage(`Successfully downloaded the document.`)
              return
            }
          })
      } catch (e) {
        loadingComponent.close()
        if (this.isDebug == true) console.debug(e)
        notifyWarning('There was a problem downloading this document.')
      }
    }

    loadingComponent.close()
  },

  async deleteDocumentMethod(name, id, category) {
    this.$buefy.dialog.confirm({
      title: `Deleting ${category}`,
      message: `Are you sure you want to <b>delete</b> this ${category}, '${name}'?`,
      confirmText: `Delete ${category}`,
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => this.deleteDocument(id || 0, category)
    })
  },

  async deleteDocument(id, category) {
    if (id && id > 0) {
      await onboardingTaskStore.dispatch('deleteOnboardingDocumentMethod', {
        onboardingDocumentID: id,
        done: () => {
          this.refreshByCategory(category)
        }
      })
    }
  },

  refreshByCategory(category) {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    if (category === 'Roster Document') {
      this.loadDocumentRosterList()
      this.loadRosterStatus()

      this.homeownersroster.clearFilesAndPreviews = true
    } else if (category === 'Governing Document') {
      this.loadDocumentGoverningList()
      this.loadGoverningStatus()

      this.governingdocuments.clearFilesAndPreviews = true
    } else if (category === 'Ancillary Document') {
      this.loadDocumentAncillaryList()
      this.loadAncillaryStatus()

      this.ancillarydocuments.clearFilesAndPreviews = true
    } else if (category === 'Financial Document') {
      this.loadDocumentFinancialList()
      this.loadFinancialDocumentStatus()

      this.financialdocuments.clearFilesAndPreviews = true
    } else {
      this.reload()
    }

    loadingComponent.close()
  },

  async saveAttachments(files, category, selectedOnboardingTypeID) {
    if (selectedOnboardingTypeID === 0) {
      notifyError('Please select an upload type.')
      return
    }

    //Process one or more attachments
    try {
      if (files && files.length > 0) {
        for (let a = 0; a < files.length; a++) {
          const tempFile = files[a]

          if (this.isDebug === true) console.debug('tempFile name =' + tempFile.name)

          await onboardingTaskStore
            .dispatch('addOnboardingDocument', {
              onboardingDocumentTypeID: selectedOnboardingTypeID,
              hoaID: this.hoaId,
              documentID: null
            })
            .then(async ({ returned }) => {
              if (returned && returned != undefined && returned.onboardingDocumentID > 0) {
                const payload = {
                  onboardingDocumentID: returned.onboardingDocumentID,
                  file: tempFile
                }

                await onboardingTaskStore
                  .dispatch('uploadOnboardingAttachment', {
                    payload: payload
                  })
                  .then(({ returnedfile }) => {
                    this.base64pdf = ''
                    if (returnedfile && returnedfile != undefined) {
                      notifyMessage(
                        `Successfully uploaded the ${category || 'onboarding'} document(s).`
                      )

                      this.refreshByCategory(category)
                    } else {
                      notifyError(
                        `There was a problem uploading your ${category ||
                          'onboarding'} document(s).`
                      )
                    }
                  })
              }
            })
        }
      }
    } catch (e) {
      notifyError(e)
    }
  },

  returnToParent() {
    this.$router.push({
      name: 'home'
    })
  }
}
