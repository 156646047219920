export const data = function() {
  return {
    isDebug: true,
    toggle: false,
    onboardingData: null,
    completedCount: '0',
    incompletedCount: '0',
    selectedID: 0,
    selectedName: '',
    selectedIndex: 0,
    selectedOnboardingTypeID: 0,
    files: [],
    previews: [],
    clearFilesAndPreviews: false,
    defaultIframeUrl: '',
    styles: {
      tilesContainer: {
        display: 'flex',
        'flex-direction': 'row',
        'flex-wrap': 'wrap',
        width: '50%'
      }
    },
    financialinfo: {
      icon: 'communications',
      title: 'Financial Information',
      subtitle:
        'Enter all relevant financial information including the Merchant Employer Identification Number, EIN.',
      financialData: {
        linkText: 'Financial Information Form'
      },
      summaryData: {
        status: ''
      }
    },
    financialdocuments: {
      icon: 'communications',
      title: 'Financial Documents',
      subtitle:
        'Upload all Financial related documents. This section will not be marked as complete until all required documents are uploaded.',
      list: [],
      typeList: [],
      requiredList: [],
      summaryData: {
        status: ''
      },
      downloadPdf: selectedID => {
        this.downloadPdf(selectedID)
      },
      deleteDocumentMethod: (selectedName, selectedID) => {
        this.deleteDocumentMethod(selectedName, selectedID, 'Financial Document')
      },
      saveAttachments: (files, category, selectedOnboardingTypeID) => {
        this.saveAttachments(files, category, selectedOnboardingTypeID)
      },
      tileCategory: 'Financial',
      clearFilesAndPreviews: false
    },
    boardAndCommittees: {
      icon: 'communications',
      title: 'Board and Committees',
      subtitle: 'Enter all relevant information for Board and Committee members.',
      boardAndCommitteesData: {
        linkText: 'Board and Committees Form'
      },
      summaryData: {
        status: ''
      }
    },
    homeownersroster: {
      icon: 'communications',
      title: 'Homeowners Roster',
      subtitle:
        'Upload all Homeowner roster related documents. These documents are not required to board a merchant.',
      list: [],
      typeList: [],
      requiredList: [],
      summaryData: {
        status: ''
      },
      downloadPdf: selectedID => {
        this.downloadPdf(selectedID)
      },
      deleteDocumentMethod: (selectedName, selectedID) => {
        this.deleteDocumentMethod(selectedName, selectedID, 'Roster Document')
      },
      saveAttachments: (files, category, selectedOnboardingTypeID) => {
        this.saveAttachments(files, category, selectedOnboardingTypeID)
      },
      tileCategory: 'Roster',
      clearFilesAndPreviews: false
    },
    governingdocuments: {
      icon: 'communications',
      title: 'Governing Documents',
      subtitle:
        'Upload all Governing related documents. This section will not be marked as complete until all required documents are uploaded.',
      list: [],
      typeList: [],
      requiredList: [],
      summaryData: {
        status: ''
      },
      downloadPdf: selectedID => {
        this.downloadPdf(selectedID)
      },
      deleteDocumentMethod: (selectedName, selectedID) => {
        this.deleteDocumentMethod(selectedName, selectedID, 'Governing Document')
      },
      saveAttachments: (files, category, selectedOnboardingTypeID) => {
        this.saveAttachments(files, category, selectedOnboardingTypeID)
      },
      tileCategory: 'Governing',
      clearFilesAndPreviews: false
    },
    ancillarydocuments: {
      icon: 'communications',
      title: 'Ancillary Documents',
      subtitle:
        'Upload all Ancillary related documents. This section will not be marked as complete until all required documents are uploaded.',
      list: [],
      typeList: [],
      requiredList: [],
      summaryData: {
        status: ''
      },
      downloadPdf: selectedID => {
        this.downloadPdf(selectedID)
      },
      deleteDocumentMethod: (selectedName, selectedID) => {
        this.deleteDocumentMethod(selectedName, selectedID, 'Ancillary Document')
      },
      saveAttachments: (files, category, selectedOnboardingTypeID) => {
        this.saveAttachments(files, category, selectedOnboardingTypeID)
      },
      tileCategory: 'Ancillary',
      clearFilesAndPreviews: false
    },
    merchantonboarding: {
      icon: 'communications',
      title: 'Merchant Onboarding',
      subtitle:
        'Enter all relevant Merchant Onboarding information. Click the Board Merchant button to board the Merchant. Further information can be entered after boarding your merchant.',
      merchantData: {
        linkText: 'Merchant Onboarding',
        hasFederalTaxID: false,
        merchantAppLink: ''
      },
      summaryData: {
        status: ''
      },
      openBoardingModal: () => {
        this.openBoardingModal()
      }
    }
  }
}
