<template>
  <div id="onboarding-vcard" :style="styles.tile" class="p-2 tile-content uploader">
    <div :style="styles.innerTile" class="bg-white p-10 rounded shadow">
      <div>
        <div
          v-if="data && data.icon"
          class="float-left rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto mb-4 is-center"
        >
          <i :class="`icon-hammer`" />
        </div>
      </div>

      <div>
        <p class="has-text-left blue-title">
          {{ `${data.title} -`
          }}<span
            v-if="
              data.summaryData && data.summaryData.status && data.summaryData.status === 'Complete'
            "
            class="has-text-left green-check"
            ><i style="color: green;" :class="`icon-check`" />{{ data.summaryData.status }}</span
          >
          <span v-else class="has-text-left orange-exclamation">{{ ` ! Incomplete` }}</span>
        </p>
        <p v-if="data.subtitle" class="has-responsive-type column is-12 grey-sub-title sub-title">
          {{ data.subtitle }}
        </p>
      </div>
      <div class="columns column is-12">&nbsp;</div>
      <div v-if="data.financialData">
        <div class="">
          <div class="href-overflow-wrap has-text-left" v-if="data.financialData.linkText">
            <a type="is-text" @click.prevent="redirectToFinancialInformation()">{{
              `${data.financialData.linkText}`
            }}</a>
          </div>
        </div>
      </div>
      <div v-if="data.boardAndCommitteesData">
        <div class="">
          <div class="href-overflow-wrap has-text-left" v-if="data.boardAndCommitteesData.linkText">
            <a type="is-text" @click.prevent="redirectToBoardAndCommittees()">{{
              `${data.boardAndCommitteesData.linkText}`
            }}</a>
          </div>
        </div>
      </div>
      <div v-if="data.merchantData">
        <div class="">
          <div
            class="href-overflow-wrap has-text-left"
            v-if="
              data.merchantData.linkText &&
                data.merchantData.hasFederalTaxID &&
                data.merchantData.hasFederalTaxID === true
            "
          >
            <a type="is-text" @click.prevent="redirectToMerchantBoarding()">{{
              `${data.merchantData.linkText}`
            }}</a>
          </div>
          <div
            v-if="data.merchantData.hasFederalTaxID === true"
            class="href-overflow-wrap has-text-left"
          >
            <a type="is-text" @click.prevent="data.openBoardingModal()">
              Merchant Boarding Form
            </a>
          </div>
          <div v-else class="href-overflow-wrap has-text-left">
            <a class="disabled" type="is-text">
              Please enter Federal Tax ID to Board Merchant
            </a>
          </div>
        </div>
      </div>
      <div class="columns pt-2">
        <b-tabs
          v-model="activeTab"
          v-if="Array.isArray(data.requiredList) || data.tileCategory"
          position="is-left"
          class="block onboarding-tab"
        >
          <b-tab-item label="Required Documents">
            <div class="column is-12">
              <ul class="has-text-left" v-if="Array.isArray(data.requiredList)">
                <li v-for="(item, index) in data.requiredList" :key="index">
                  <div class="columns">
                    <div class="font-bold column is-8">{{ item.col1 }}</div>
                    <span
                      v-if="item.col2 === 'Yes'"
                      class="has-text-left green-check-small column is-3"
                      ><i style="font-size: 8pt;" class="icon-check" /><span
                        style="vertical-align: top;"
                        >Uploaded</span
                      ></span
                    >
                    <span v-else class="has-text-left pl-5 orange-exclamation-small column is-3">{{
                      `! Missing`
                    }}</span>
                    <span v-if="item.col2 === 'No'" class="column is-1 pl-6 has-text-left">
                      <button @click.prevent="activateUploadTab()">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          version="1.1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path d="M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z" />
                        </svg></button
                    ></span>
                  </div>
                </li>
              </ul>
              <ul
                v-if="data.requiredList && data.requiredList.length === 0"
                class="has-text-left font-bold"
              >
                <li>None</li>
              </ul>
            </div>
          </b-tab-item>
          <b-tab-item key="upload" value="upload" label="Uploaded Documents">
            <div :class="`${window.width > 1222 ? 'columns is-6' : ''}`">
              <div class="pr-5 pb-5">
                <ul class="" v-if="Array.isArray(data.list)">
                  <li
                    v-for="(item, index) in data.list"
                    :key="index"
                    class="bg-white odd:bg-gray-200 p-2 flex"
                    :style="styles.listItem"
                  >
                    <div class="w-2/3 font-bold">{{ item.col1 }}</div>
                    <div :style="styles.listItemRight" class="w-1/3 text-left">
                      <li v-html="item.col2"></li>
                      {{ window.width }}
                    </div>
                    <div class="w-1/3 text-right" v-if="item.col3">
                      <button @click.prevent="data.downloadPdf(item.col3)">
                        <img class="fill-1" src="@/assets/images/fill-1.svg" alt="download" />
                      </button>
                      <button
                        class="w-5 h-5 ml-4"
                        @click="data.deleteDocumentMethod(item.col2, item.col3)"
                      >
                        <img class="fill-1" src="@/assets/images/group-7.svg" alt="delete" />
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
              <div v-if="data.tileCategory" class="onboarding-image-upload">
                <b-field class="file">
                  <b-upload
                    v-model="files"
                    multiple
                    drag-drop
                    accept="application/pdf"
                    class="rectangle"
                  >
                    <section>
                      <div>
                        <div>
                          <img
                            class="Fill-1-Copy-11"
                            src="@/assets/images/fill-1-copy-11.svg"
                            alt="Upload"
                          />
                        </div>
                        <div>
                          <div class="Drag-Drop-your-fil">
                            <div class="text-style-1">Drag & Drop</div>
                            <div>your files here to upload</div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </b-upload>
                </b-field>
                <ul v-if="previews" id="gallery" class="flex flex-1 flex-wrap -m-1">
                  <li v-show="!previews.length" class="h-full w-full flex flex-col">
                    <span class="text-small text-gray-500 is-center pl-3">No files selected</span>
                  </li>
                  <li v-for="(image, index) in previews" :key="index" class="p-1 flex w-1/4 h-32">
                    <div
                      class="align-items-left relative w-full h-full bg-cover"
                      :style="`background-image: url(${image});`"
                    >
                      <button
                        class="delete is-small"
                        type="button"
                        @click="deleteDropFile(index)"
                      />
                      <label class="absolute inset-x-0 bottom-0 bg-black bg-opacity-50 z-10 p-2">
                        <span style="color:white;">{{ files[index].name }}</span>
                      </label>
                    </div>
                  </li>
                </ul>
                <div class="pt-5" v-if="data.typeList" style="width: 100%;">
                  <select
                    required="Please select a type"
                    class="select-border"
                    v-model="selectedOnboardingTypeID"
                  >
                    <option
                      v-for="option in data.typeList"
                      :key="option.value"
                      :value="option.value"
                      >{{ option.label || '' }}</option
                    >
                  </select>
                </div>
                <div class="pt-3">
                  <b-button
                    expanded
                    type="is-info"
                    @click="data.saveAttachments(files, tileCategory, selectedOnboardingTypeID)"
                    :style="{ float: 'left' }"
                    >Upload Now</b-button
                  >
                </div>
              </div>
            </div>
          </b-tab-item>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { notifyProblem } from '@/services/notify'
import { mapState } from 'vuex'

export default {
  name: 'OnBoardingCard',

  components: {},

  props: {
    data: {
      icon: String,
      title: String,
      subtitle: String,
      financialData: Object,
      merchantData: Object,
      summaryData: Object,
      list: [],
      requiredList: [],
      typeList: [],
      downloadPdf: Function,
      deleteDocumentMethod: Function,
      saveAttachments: Function,
      openBoardingModal: Function
    },
    selectedID: Number,
    selectedIndex: Number,
    selectedName: String,
    tileCategory: String,
    clearFilesAndPreviews: Boolean
  },

  data: function() {
    // text bubble content
    var difference = '?'

    // % slider number
    var difference_appearance = 0

    return {
      window: {
        width: 0,
        height: 0
      },
      files: [],
      previews: [],
      difference,
      difference_appearance,
      selectedOnboardingTypeID: 0,
      activeTab: 0,
      styles: {
        dropdown: {
          position: 'absolute',
          bottom: '2.5rem',
          left: 0,
          width: '100%',
          justifyContent: 'center'
        },
        dropdownButton: {
          display: 'flex',
          'align-items': 'center'
        },
        financialSummary: {
          marginBottom: '4rem'
        },
        assetSummary: {
          marginBottom: '4rem'
        },
        listItem: {
          'align-items': 'left',
          'text-align': 'left'
        },
        listItemRight: {
          width: '50%'
        },
        textBubble: {
          top: '20px',
          left: '-78.5px',
          color: '#FFF'
        },
        tile: {
          minWidth: '480px'
        },
        innerTile: {
          position: 'relative',
          height: '100%',
          paddingBottom: '7rem'
        },
        upArrow: {
          position: 'absolute',
          top: '-5px',
          left: '50%',

          width: 0,
          height: 0,
          'border-left': '5px solid transparent',
          'border-right': '5px solid transparent',
          'border-bottom': '5px solid #2baae1'
        }
      }
    }
  },

  created() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    })
  },

  mounted() {
    this.clearPreviewsAndFiles()
    this.selectedOnboardingTypeID = 0
  },

  watch: {
    files() {
      this.updateAttachments()
    },
    'data.clearFilesAndPreviews'() {
      this.clearPreviewsAndFiles()
    },
    'data.list'() {
      this.clearPreviewsAndFiles()
    }
  },

  methods: {
    activateUploadTab() {
      if (this.isDebug === true) console.debug('activateUploadTab...')
      this.activeTab = 1
    },
    clearPreviewsAndFiles() {
      console.debug('clearFilesAndPreviews...................')
      this.previews = []
      this.files = []
    },
    redirectToFinancialInformation() {
      console.debug('in redirectToFinancialInformation()...')
      this.$router.push({
        name: 'financialInformation'
      })
    },
    redirectToBoardAndCommittees() {
      console.debug('in redirectToBoardAndCommittees()...')
      this.$router.push({
        name: 'boardAndCommittees'
      })
    },

    redirectToMerchantBoarding() {
      console.debug('in redirectToMerchantBoarding()...')
      this.$router.push({
        name: 'merchantBoarding',
        params: {
          applink:
            this.data.merchantData.merchantAppLink &&
            this.data.merchantData.merchantAppLink !== undefined
              ? this.data.merchantData.merchantAppLink
              : ''
        }
      })
    },

    async deleteDropFile(index) {
      this.files.splice(index, 1)
      this.previews.splice(index, 1)
    },

    async updateAttachments() {
      const attachments = this.files
      if (!Array.isArray(attachments)) {
        notifyProblem('A problem occurred and the attachments could not be uploaded.')
        return
      }

      this.previews = this.files.map(file => {
        return URL.createObjectURL(file)
      })
    },

    destroyed() {
      window.removeEventListener('resize', this.handleResize)
    },

    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
    }
  }
}
</script>
<style lang="scss">
#onboarding-vcard {
  .onboarding-image-upload {
    .modal-close,
    .delete {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 20;
      margin: 5px 5px 0 0;
    }
  }

  .onboarding-tab {
    .tabs li.is-active a {
      border-bottom-color: #40b6cf;
      color: #40b6cf;
      font-weight: 900 !important;
    }
    .tabs a {
      color: #9a9d9d;
    }
  }

  .blue-title {
    color: #112d4f;
    font-weight: 520;
    font-size: 20pt;
    vertical-align: sub;
    text-indent: 20px;
  }

  .grey-sub-title {
    color: #6e7776;
    font-size: 13pt;
    text-align: left;
    vertical-align: text-top;
    text-indent: 20px;
  }

  .green-check {
    color: #2ab474;
    font-size: 20pt;
    font-weight: 520;
  }

  .green-check-small {
    color: #2ab474;
    font-size: 12pt;
    font-weight: 700;
    white-space: nowrap !important;
  }

  .orange-exclamation {
    color: #de8249;
    font-size: 20pt;
    font-weight: 520;
  }

  .orange-exclamation-small {
    color: #de8249;
    font-size: 12pt;
    font-weight: 700;
    white-space: nowrap !important;
  }

  .grey-text {
    color: #6e7776;
  }

  .select-border {
    border: solid 1px;
  }
}

.uploader {
  .rectangle {
    width: 244px;
    height: 107px;
    margin: 13px 16px 11px 53px;
    padding: 18px 25px 8px 3px;
    border-radius: 4px;
    border: #979797;
  }
  .Drag-Drop-your-fil {
    width: 192px;
    height: 34px;
    margin: 7px 0 8px 4px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center !important;
    color: #838383;
  }

  .Drag-Drop-your-fil .text-style-1 {
    font-weight: bold;
  }

  img.Fill-1-Copy-11 {
    width: 24px;
    height: 30px;
    margin: 0 85px 7px 83px;
    object-fit: contain;
  }
}
</style>
