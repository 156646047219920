/*
  import { onboardingTaskStore } from '@/services/OnboardingTask/store'
*/

import Vue from 'vue'
import Vuex from 'vuex'
import kms from '@/services/kms'
import { notifyMessage, notifyProblem, notifyError } from '@/services/notify'
import _get from 'lodash/get'

const isDebug = true

/* eslint-disable no-empty-pattern */
const actions = {
  async uploadOnboardingAttachment({}, { payload }) {
    const formData = new FormData()
    formData.append('file', payload.file)

    if (isDebug === true)
      console.debug('uploadOnboardingAttachment payload file=' + payload.file.name)

    try {
      const path = `/Onboarding/OnboardingDocument/StoreFile?onboardingDocumentID=${payload.onboardingDocumentID}`
      const uploadedFile = await kms.post(path, formData, {
        'Content-Type': 'multipart/form-data'
      })

      if (isDebug == true)
        console.debug('uploadOnboardingAttachment with payload=' + JSON.stringify(uploadedFile))

      return {
        returnedfile: uploadedFile
      }
    } catch (exception) {
      notifyProblem('There was a problem uploading the onboarding attachment.')
      console.error(exception)
    }

    return {
      returnedfile: null
    }
  },

  async getOnboardingTaskByCodeName({}, { hoaID, codeName }) {
    try {
      if (isDebug === true) console.debug('in getOnboardingTaskByCodeName...' + codeName)

      const onboardingTaskType = await kms.get(
        `/Onboarding/OnboardingTaskType/GetByCodeName?codeName=${codeName}`
      )

      if (isDebug === true)
        console.debug('in onboardingTaskType...' + JSON.stringify(onboardingTaskType))
      if (onboardingTaskType && onboardingTaskType != undefined) {
        const result = await kms.get(
          `/Onboarding/OnboardingTask/List?hoaID=${hoaID}&autoProcessCompletionDates=true`
        )

        if (isDebug === true)
          console.debug('in getOnboardingTaskByCodeName result...' + JSON.stringify(result.results))

        const filtered = result.results
          .map(a => ({
            ...a
          }))
          .filter(f => f.onboardingTaskTypeID === onboardingTaskType.onboardingTaskTypeID)[0]

        if (isDebug == true) console.debug('getOnboardingData=' + JSON.stringify(filtered))

        return {
          result: filtered || null
        }
      }
    } catch (exception) {
      console.debug(`There was a problem retrieving the onboarding tile status.`)
      console.error(exception)
    }

    return {
      result: {}
    }
  },

  async getDocumentList({}, { hoaID, category }) {
    console.log('in getRosterList...')
    try {
      const result = await kms.get('/Onboarding/OnboardingDocument/List', {
        params: {
          hoaID,
          category
        }
      })

      if (isDebug == true) console.debug('getDocumentList log=' + JSON.stringify(result.results))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The document list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async getDocumentTypeList({}, { category, requiredOnly, hoaID }) {
    console.log('in getDocumentTypeList...')
    try {
      const result = await kms.get('/Onboarding/OnboardingDocumentType/List', {
        params: {
          category,
          requiredOnly,
          hoaID
        }
      })

      if (isDebug == true)
        console.debug('getDocumentTypeList log=' + JSON.stringify(result.results))

      return {
        list: result.results
      }
    } catch (exception) {
      notifyProblem(`The document type list could not be retrieved.`)
      console.error(exception)
    }

    return {
      list: []
    }
  },

  async deleteOnboardingDocumentMethod({ dispatch }, { onboardingDocumentID, done }) {
    try {
      const path = `/Onboarding/OnboardingDocument/${onboardingDocumentID}?forceDelete=false`

      const results = await kms.delete(path)

      if (isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      const wasDeleted = _get(results, 'deleted', false)

      if (wasDeleted === true) {
        notifyMessage(`Successfully deleted this Onboarding Document.`)
        done()
      } else {
        notifyError('There was a problem deleting this Onboarding Document.')
      }
    } catch (exception) {
      notifyProblem(`There was a problem deleting this Onboarding Document.`)
      console.error(exception)
    }
  },

  async updateOnboardingAttachment({ dispatch }, { payload = {}, done }) {
    try {
      const results = await kms.put(`/Onboarding/OnboardingDocument`, payload)

      if (this.isDebug == true) console.debug('results=' + JSON.stringify(results))

      if (dispatch && dispatch != undefined) console.debug(dispatch)

      if (results.onboardingDocumentID > 0) {
        if (payload.remove && payload.reomove != undefined && payload.remove == true) {
          notifyMessage(`Successfully removed the onboarding document.`)
        } else {
          notifyMessage(`Successfully updated the onboarding document.`)
        }
        done()
      } else {
        notifyError('There was a problem updating this onboarding document.')
      }
    } catch (exception) {
      notifyProblem('There was a problem updating this onboarding document.')
      console.error(exception)
    }
  },

  async addOnboardingDocument({}, { onboardingDocumentTypeID, hoaID, documentID }) {
    try {
      const onboardingDocument = await kms.post(`/Onboarding/OnboardingDocument`, {
        onboardingDocumentTypeID,
        hoaID,
        documentID
      })

      if (isDebug == true)
        console.debug('addOnboardingDocument=' + JSON.stringify(onboardingDocument))

      return {
        returned: onboardingDocument
      }
    } catch (exception) {
      notifyProblem('There was a problem adding the onboarding document data.')
      console.error(exception)
    }

    return {
      returned: null
    }
  }
}
/* eslint-enable no-empty-pattern */

Vue.use(Vuex)
export const onboardingTaskStore = new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions
})
