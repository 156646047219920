<template>
  <div boarding-modal>
    <div class="is-size-4" v-if="formData">{{ buttonText }} Merchant Boarding</div>
    <ValidationObserver ref="formGroup" v-slot="{ handleSubmit }">
      <transition name="fade" mode="out-in" appear>
        <form @submit.prevent="handleSubmit(submitBoarding)">
          <fieldset>
            <div class="form-grid">
              <div class="column is-12">
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.merchantEmail"
                    label="Email"
                    name="merchantEmail"
                    type="text"
                    vid="merchantEmail"
                    placeholder="Email"
                    rules="required|max:200"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.merchantDbaName"
                    label="Dba Name"
                    name="merchantDbaName"
                    type="text"
                    vid="merchantDbaName"
                    placeholder="Dba Name"
                    rules="required|max:100"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.merchantTemplateID"
                    label="Template ID"
                    name="merchantTemplateID"
                    type="text"
                    vid="merchantTemplateID"
                    placeholder="Template ID"
                    rules="required|max:50"
                    :disabled="true"
                    :readonly="true"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.merchantPhoneNumber"
                    label="Phone Number"
                    name="merchantPhoneNumber"
                    type="text"
                    vid="merchantPhoneNumber"
                    placeholder="Phone Number"
                    rules="required|max:20"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.merchantFirstName"
                    label="First Name"
                    name="merchantFirstName"
                    type="text"
                    vid="merchantFirstName"
                    placeholder="First Name"
                    rules="required|max:100"
                  />
                </div>
                <div class="column is-12" style="padding:10px; white-space:nowrap;">
                  <valid-input
                    v-model="formData.merchantLastName"
                    label="Last Name"
                    name="merchantLastName"
                    type="text"
                    vid="merchantLastName"
                    placeholder="Last Name"
                    rules="required|max:100"
                  />
                </div>
                <div class="pt-5">
                  <button type="submit" :disabled="loading" class="button is-primary is-rounded">
                    {{ buttonText }}
                  </button>
                  <span class="pl-3"></span>
                  <button
                    v-if="
                      !onboardingData.merchantAppLink ||
                        onboardingData.merchantAppLink === undefined ||
                        onboardingData.merchantAppLink === ''
                    "
                    type="button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                    @click.prevent="boardMerchant()"
                  >
                    Board Merchant
                  </button>
                  <span class="pl-3"></span>
                  <button
                    type="button"
                    :disabled="loading"
                    class="button is-primary is-rounded"
                    @click.prevent="closeModal()"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset>
            <ValidationProvider vid="providerErrors" v-slot="{ errors }">
              <div class="field">
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </fieldset>
        </form>
      </transition>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { mapState, mapGetters } from 'vuex'
import { data } from './keys/data'
import { methods } from './keys/methods'
import ValidInput from '@/components/inputs/ValidInput'

export default {
  props: {
    onboardingData: Object
  },

  components: {
    ValidInput,
    ValidationObserver,
    ValidationProvider
  },

  mounted() {
    this.reload()
  },

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId,
      ...mapGetters('user', ['authUser'])
    })
  },

  data,
  methods
}
</script>
