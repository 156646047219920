export function data() {
  return {
    formData: {
      onboardingDataID: 0,
      merchantAppLink: '',
      merchantEmail: '',
      merchantDbaName: '',
      merchantTemplateID: '',
      merchantPhoneNumber: '',
      merchantFirstName: '',
      merchantLastName: '',
      merchantAppDelivery: ''
    },
    isDebug: true,
    isOpen: false,
    loading: true,
    isFetching: false,
    openOnFocus: true,
    buttonText: 'Add',
    templateID: '',
    iframeUrl: ''
  }
}
