<template>
  <PageContentLayoutOnly role="region" merchant-boarding>
    <section class="section">
      <div class="pl-5 pr-5">
        <div class="pt-5 level-left level-item title">{{ $t('merchantBoarding.title') }}</div>
        <div v-if="appLink" class="box is-8">
          <iframe style="height: 100vh; width: 100vh; border: none" :src="appLink"></iframe>
        </div>
        <div v-else class="box is-8">
          The Merchant App Link is not available at this time. Please submit your Merchant Boarding
          information to retrieve the Merchant App Link.
        </div>
      </div>
    </section>
  </PageContentLayoutOnly>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import PageContentLayoutOnly from '@/containers/PageContentLayoutOnly'
import { data } from './keys/data'
import { methods } from './keys/methods'

export default {
  components: {
    PageContentLayoutOnly
  },

  data,

  computed: {
    ...mapState({
      hoaId: state => state.user.selectedHoaId
    }),
    ...mapGetters('user', ['authUser'])
  },

  watch: {
    hoaId() {
      this.reload()
    }
  },

  mounted() {
    this.reload()
  },

  methods,

  i18n: {
    messages: {
      en: { merchantBoarding: { title: 'Merchant Boarding' } }
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-avatar {
  margin: 1em auto 2em;
}

.card {
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.05) !important;

  .card {
    border: 0 !important;
  }
}

.avatar-img {
  border: 1px solid #e7e8f2;
  padding: 0.25em;
}

.address-card {
  h5,
  h6 {
    margin: 0;
  }
}

.details-img {
  img {
    border-radius: 0.5em;
  }
}
</style>
