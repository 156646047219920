import { onboardingDataStore } from '@/services/OnboardingData/store'

import $ from 'jquery'
import { notifyError } from '@/services/notify'
import { notifyMessage } from '@/services/notify'
import _get from 'lodash/get'

export const methods = {
  async reload() {
    this.loading = true

    console.log('onboardingData=' + JSON.stringify(this.onboardingData))

    await this.getMerchantBoarding()

    if (this.onboardingData && this.onboardingData != undefined) {
      //Update
      this.buttonText = 'Save'

      this.formData.onboardingDataID = this.onboardingData.onboardingDataID
      this.formData.merchantAppLink = this.onboardingData.merchantAppLink
      this.formData.merchantEmail = this.onboardingData.merchantEmail
      this.formData.merchantDbaName = this.onboardingData.merchantDbaName
      this.formData.merchantTemplateID = this.onboardingData.merchantTemplateID
      this.formData.merchantPhoneNumber = this.onboardingData.merchantPhoneNumber
      this.formData.merchantFirstName = this.onboardingData.merchantFirstName
      this.formData.merchantLastName = this.onboardingData.merchantLastName
      this.formData.merchantAppDelivery = this.onboardingData.merchantAppDelivery
      this.loading = false
    } else {
      this.buttonText = 'Add'
      this.loading = false
      notifyError('Please enter your Federal Tax ID under Financial Information.')
    }

    if (this.iframeUrl !== undefined && this.iframeUrl) {
      this.formData.merchantAppLink = this.iframeUrl
    }

    if (this.templateID !== undefined && this.templateID) {
      this.formData.merchantTemplateID = this.templateID
    }

    this.loading = false
  },

  open() {
    this.formData = {
      onboardingDataID: 0,
      merchantAppLink: '',
      merchantEmail: '',
      merchantDbaName: '',
      merchantTemplateID: '',
      merchantPhoneNumber: '',
      merchantFirstName: '',
      merchantLastName: '',
      merchantAppDelivery: ''
    }
    this.isOpen = true
    this.loading = false
  },

  async submitBoarding() {
    if (this.formData && this.formData != undefined) {
      if (this.formData.onboardingDataID && this.formData.onboardingDataID > 0) {
        await this.updateMerchantBoarding()
      } else {
        notifyError(
          'Please enter your Federal Tax ID under Financial Information prior to filling this form out.'
        )
      }
    }
  },

  async getMerchantBoarding() {
    try {
      await onboardingDataStore
        .dispatch('getMerchantBoardingData', {
          hoaID: this.hoaId
        })
        .then(({ result }) => {
          if (result) {
            this.templateID = _get(result, 'merchantBoardingTemplateID', '')
            this.iframeUrl = _get(result, 'merchantBoardingIframeUrl', '')
          }
        })
    } catch (exception) {
      console.debug(exception.message)
    }
  },

  async updateMerchantBoarding() {
    if (!this.formData || this.formData == undefined) {
      notifyError('There was a problem entering this merchant.')
      return
    }

    if (!this.onboardingData.federalTaxID || this.onboardingData.federalTaxID == undefined) {
      notifyError(
        'Please enter a Federal Tax ID under financial information prior to entering your merchant information.'
      )
      return
    }

    this.loading = true
    const payload = {
      onboardingDataID: this.onboardingData.onboardingDataID || null,
      hoaID: this.hoaId,
      merchantEmail: this.formData.merchantEmail ? this.formData.merchantEmail : '',
      merchantDbaName: this.formData.merchantDbaName ? this.formData.merchantDbaName : '',
      merchantTemplateID: this.formData.merchantTemplateID ? this.formData.merchantTemplateID : '',
      merchantPhoneNumber: this.formData.merchantPhoneNumber
        ? this.formData.merchantPhoneNumber
        : '',
      merchantFirstName: this.formData.merchantFirstName ? this.formData.merchantFirstName : '',
      merchantLastName: this.formData.merchantLastName ? this.formData.merchantLastName : '',
      merchantAppDelivery: 'link_iframe',
      federalTaxID: this.onboardingData.federalTaxID,
      updateMerchantOnly: true
    }

    await onboardingDataStore.dispatch('updateOnboardingMerchantData', {
      payload: payload,
      done: ({ results }) => {
        if (results) {
          if (
            this.onboardingData.merchantAppLink &&
            this.onboardingData.merchantAppLink != undefined &&
            this.onboardingData.merchantAppLink !== ''
          ) {
            this.closeModal()
          }

          notifyMessage('Successfully saved your merchant information.')
        }
      }
    })

    this.loading = false
  },

  async boardMerchant() {
    if (this.isDebug === true) console.debug('boardMerchant...')
    this.loading = true

    const payload = {
      template_id: this.formData.merchantTemplateID
        ? parseInt(this.formData.merchantTemplateID)
        : 0,
      client_app_id: null,
      dba_name: this.formData.merchantDbaName ? this.formData.merchantDbaName : '',
      app_delivery: 'link_iframe',
      email: this.formData.merchantEmail ? this.formData.merchantEmail : '',
      website: null,
      ownership_type: null,
      fed_tax_id: this.onboardingData.federalTaxID,
      cc_average_ticket_range: 0,
      cc_monthly_volume_range: 0,
      cc_high_ticket: 0,
      ec_average_ticket_range: 0,
      ec_monthly_volume_range: 0,
      ec_high_ticket: 0,
      business_type: 'na',
      business_category: 'na',
      business_description: 'na',
      swiped_percent: 0,
      keyed_percent: 0,
      ecommerce_percent: 0,
      legal_name: null,
      location: {
        address_line_1: null,
        address_line_2: null,
        city: null,
        state_province: null,
        postal_code: null,
        phone_number: this.formData.merchantPhoneNumber ? this.formData.merchantPhoneNumber : ''
      },
      primary_principal: {
        first_name: this.formData.merchantFirstName ? this.formData.merchantFirstName : '',
        last_name: this.formData.merchantLastName ? this.formData.merchantLastName : '',
        middle_name: null,
        title: null,
        date_of_birth: null,
        address_line_1: null,
        address_line_2: null,
        city: null,
        state_province: null,
        postal_code: null,
        ssn: null,
        ownership_percent: 0,
        phone_number: this.formData.merchantPhoneNumber ? this.formData.merchantPhoneNumber : ''
      },
      contact: {
        first_name: null,
        last_name: null,
        email: null,
        phone_number: this.formData.merchantPhoneNumber ? this.formData.merchantPhoneNumber : ''
      },
      bank_account: {
        routing_number: '0',
        account_number: '0',
        account_holder_name: `${
          this.formData.merchantFirstName ? this.formData.merchantFirstName : ''
        } ${this.formData.merchantLastName ? this.formData.merchantLastName : ''}`
      },
      is_test: 'true',
      app_complete_endpoint: null
    }

    await onboardingDataStore.dispatch('fortisBoardMerchant', {
      payload: payload,
      done: ({ results }) => {
        if (results) {
          notifyMessage('Successfully boarded this merchant.')
        }
      }
    })

    this.loading = false
  },

  closeModal() {
    // auto-close modal
    this.$emit('update:toggle', (this.toggle = false))
    $('#clickclose').click()
    this.loading = false
  }
}
