import { onboardingDataStore } from '@/services/OnboardingData/store'

export const methods = {
  async reload() {
    this.loading = true

    if (this.isDebug === true) console.debug('route=' + JSON.stringify(this.$route.params))

    this.appLink =
      this.$route.params && this.$route.params.applink && this.$route.params.applink != undefined
        ? this.$route.params.applink
        : ''

    await this.loadFinancialInformation()

    this.loading = false
  },

  async loadFinancialInformation() {
    const loadingComponent = this.$buefy.loading.open({
      container: null
    })

    try {
      await onboardingDataStore
        .dispatch('getOnboardingData', {
          hoaID: this.hoaId
        })
        .then(({ result }) => {
          if (result) {
            if (this.isDebug === true)
              console.debug('loadFinancialInformation=' + JSON.stringify(result))

            this.financialInformation = result

            this.federalTaxId = result.federalTaxID || ''
          }
        })
    } catch (exception) {
      loadingComponent.close()
    }

    loadingComponent.close()
  },

  async onFormSubmit() {
    if (this.isDebug == true) console.debug('in onFormSubmit....' + this.federalTaxId)

    if (
      this.financialInformation &&
      this.financialInformation.onboardingDataID &&
      this.financialInformation.onboardingDataID != undefined
    ) {
      const payload = {
        onboardingDataID: this.financialInformation.onboardingDataID,
        hoaID: this.hoaId,
        federalTaxID: this.federalTaxId,
        updateFederalTaxOnly: true
      }

      await onboardingDataStore.dispatch('updateOnboardingFederalIdData', {
        payload: payload,
        done: () => {}
      })
    } else {
      await onboardingDataStore.dispatch('addOnboardingFederalIdData', {
        hoaID: this.hoaId,
        federalTaxID: this.federalTaxId,
        done: () => {}
      })
    }
  }
}
